export const ABOUT_US = `FÉNIX CONSULTORÍA, es una consultoría, cuya finalidad es brindarle asesoría y
apoyo en el desarollo de  proyectos para la comercialización de insumos para la salud en México con base 
en la regulación sanitaria aplicable; buscando siempre cubrir las expectativas y necesidades de nuestros clientes.`;

export const PROJECTS_DIVISION = `Brindamos asesoría y coordinación regulatoria de proyectos de emprendimiento de
diversos modelos de negocio para la industria farmacéutica y de dispositivos médicos.`;

export const TRAINING_DIVISION = `Amplia oferta de seminarios, cursos y talleres para diferentes niveles de experiencia 
en el área de regulación sanitaria.`;

export const PROCEDURES_DIVISION = `Trabajamos en la integración de expedientes de trámites de registro sanitario de insumos 
para la salud.`;

export const ANALYTICS_DIVISION = `Proveemos diferentes servicios analíticos acorde al cumplimiento regulatorio mexicano 
a través del acompañamiento y respaldo de personal experto y calificado.`;

export const CLINIC_DIVISION = `Respaldamos el potencial comercial de medicamentos de terapias avanzadas y moléculas nuevas 
con base en los padecimientos más frecuentes.`;

export const MAIN_DESCRIPTION_1 = `FÉNIX CONSULTORÍA, es una consultoría, cuya finalidad es brindarle asesoría y
apoyo en el desarollo de  proyectos para la comercialización de insumos para la salud en México con base 
en la regulación sanitaria aplicable; buscando siempre cubrir las expectativas y necesidades de nuestros clientes.`;

export const MAIN_DESCRIPTION_2 = `Nuestro equipo cuenta con una amplia experiencia en esta área por lo que 
podemos garantizar que nuestros servicios son de la más alta calidad.`;

export const URANIA_PROFILE = `Maestra en Ciencias de Innovación Biotecnológica y Licenciada Quimica Farmacobióloga.`;

export const URANIA_PROFILE_EXPERIENCE = `Empresaria, especialista y capacitadora profesional certificada en regulación 
sanitaria mexicana con amplio dominio de los procesos operativos de la industria farmacéutica y de dispositivos médicos para el
desarrollo de estrategias regulatorias óptimas y eficientes aplicables a cada modelo de negocio, así como extensa experiencia en
trámites y autorizaciones sanitarias diversas de productos y establecimientos de fabricación nacional y extranjera.`;

export const TEXT = `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae autem temporibus corrupti porro, iure officia dicta aut. 
Voluptatum inventore nihil, cum, dolorem temporibus quos delectus nostrum facere quaerat blanditiis dignissimos.`;

export const PROJECTS_TEXT1 = `Brindamos asesoría y coordinación regulatoria de proyectos de emprendimiento de diversos modelos de negocio para 
la industria farmacéutica y de dispositivos medicos.`;

export const PROJECTS_BULLET1 = `Recopilación y análisis de información del modelo de negocio de interés.`;

export const PROJECTS_BULLET2 = `Propuesta de proyecto alineado a las vías regulatorias posibles y aplicables al modelo de negocio establecido.`;

export const PROJECTS_BULLET3 = `Planteamiento de fases, etapas y actividades, tiempos y costos del proceso.`;

export const PROJECTS_BULLET4 = `Gestión de trámites regulatorios para la obtención de las notificaciones y autorizaciones sanitarias que requiera
el proyecto.`;

export const PROJECTS_BULLET5 = `Entrega de todas las notificaciones y/o autorizaciones sanitarias obtenidas, así como de la evidencia documental
generada para el cumplimiento de las actividades regulatorias del proyecto.`;

export const PROCEDURES_TITLE = `Además de la integración de expedientes de trámites de registro sanitario de insumos para la salud,
realizamos cualquier tipo de notificación y/o autorización sanitaria tanto para establecimientos como para productos que requiera su
empresa tales como:`;

export const PROCEDURES_BULLET1 = `Avisos de Funcionamiento y Licencias Sanitarias.`;

export const PROCEDURES_BULLET2 = `Permisos de importación y Certificados de Libre Venta.`;

export const PROCEDURES_BULLET3 = `Permisos y Avisos de Publicidad.`;

export const PROCEDURES_BULLET4 = `Alta de Unidad de Farmacovigilancia.`;

export const PROCEDURES_TEXT1 = `Paquete completo: elaboración, recolección, revisión, conformación y sometimiento.`;

export const PROCEDURES_BULLET5 = `Elaboración, actualización y/o recolección/solicitud de la documentación correspondiente conforme a la lista de
verificación emitida por COFEPRIS.`;

export const PROCEDURES_BULLET6 = `Asesoría y revisión de la documentación proporcionada por el cliente, proveedor y/ fabricante.`;

export const PROCEDURES_BULLET7 = `Conformación del expediente una vez que se tenga completa toda la documentación conforme a los requerimientos COFEPRIS.`;

export const PROCEDURES_BULLET8 = `Seguimiento del trámite hasta la obtención y entrega de la autorización sanitaria.`;

export const TRAINING = `Contamos con una amplia oferta de cursos teórico-prácticos en todas las modalidades para cubrir las 
necesidades de capacitación personales y/o empresariales con diversas facilidades y alternativas de pago. Nuestros cursos están 
diseñados desde la práctica regulatoria al interior de las industrias de insumos para la salud en apego a los procesos y a su vez 
basados en estricta concordancia con todos los fundamentos normativos aplicables.`;

export const ANALYTICS_SERVICES = `Proveemos diferentes servicios analíticos acorde al cumplimiento regulatorio mexicano a través 
del acompañamiento y respaldo de personal experto y calificado con el fin de posibilitar los objetivos de cada modelo de negocio, 
así como explorar nuevas oportunidades.`;

export const ANALYTICS_BULLET1 =`Implementación de un Sistema de Gestión de Calidad para que ejecute rutinariamente lo requerido 
por COFEPRIS.`;

export const ANALYTICS_BULLET2 = `Cotejo analítico de los atributos críticos de calidad como producto terminado para verificar 
aplicabilidad en México.`;

export const ANALYTICS_BULLET3 = `Emisión de Especificaciones/Metodología de análisis del producto terminado regionalizado 
para distribuir a proveedores de servicio de análisis.
`;

export const ANALYTICS_BULLET4 = `Búsqueda y evaluación de insumos y proveedores de servicio de análisis. `;

export const ANALYTICS_BULLET5 = `Gestión de la ejecución de la Transferencia de Tecnología Analítica. `;

export const ANALYTICS_BULLET6 = `Pre-liberación de lotes comercializados en México.`;

export const ANALYTICS_BULLET7 = `Implementación del proceso de ejecución de Estudios de Estabilidad.`;

export const CLINIC_SERVICES = `Respaldamos el potencial comercial de medicamentos de terapias avanzadas y moléculas 
nuevas con base en los padecimientos más frecuentes y las necesidades más urgentes de la población mexicana mediante: `;

export const CLINIC_SUBBULLET1 = `La presentación de la evidencia científica y/o la realización de los estudios clínicos 
aplicables.`;

export const CLINIC_SUBBULLET2 = `La conducción de las actividades de  Farmacovigilancia y minimización de riesgos 
adicionales durante la etapa de post-comercialización.`;

export const CLINIC_BULLET1 = `Investigación y análisis de toda la literatura cientifica aplicable para los planes 
de manejo de riesgos y la solicitud de evaluación por el Comité de Moléculas Nuevas.`;

export const CLINIC_BULLET2 = `Implementación de los estudios de bioequivalencia y estudios clínicos requeridos 
para el medicamento de interés, a través de alianzas operativas.`;

export const CLINIC_BULLET3 = `Coordinación y monitoreo de la actividades de farmacovigilancia adicionales para 
la mitigación de riesgos potenciales relacionados con medicamentos de terapias avanzadas o moléculas nuevas en 
México a través de alianzas con instituciones de salud.`;

